import storage from '@/util/storage'
import { useCookies } from '@vueuse/integrations/useCookies'
import useTabbarStore from './tabbar'
import useRouteStore from './route'
import useMenuStore from './menu'
import { login as apiLogin, logout as apiLogout } from '@/api/auth'
import dayjs from 'dayjs'
import { getUserPermission } from '@/api/common/user'
import { getInclude } from '@/util'

const useUserStore = defineStore(
    'user',
    {
        state: () => ({
            id: storage.local.get('id') || '',
            account: storage.local.get('account') || '',
            token: storage.local.get('token') || useCookies(['token']).get('token') || '',
            expiredAt: storage.local.get('expiredAt') || '',
            permissions: []
        }),
        getters: {
            isLogin: state => {
                return !!(state.token && new Date().getTime() < state.expiredAt)
            }
        },
        actions: {
            async login(data) {
                const res = await apiLogin({
                    mobile: data.mobile,
                    captchaCode: data.captchaCode,
                    captchaId: data.captchaId,
                    name: data.name,
                    captchaVerifyParam: data.captchaVerifyParam
                }).catch(() => {
                    return { verifyIntelligentCaptcha: true, accessToken: null }
                })
                this.token = res.accessToken
                this.expiredAt = dayjs(res.expiredAt).toDate().getTime()
                storage.local.set('token', this.token)
                storage.local.set('id')
                storage.local.set('expiredAt', this.expiredAt)
                useCookies(['token']).set('token', this.token)
                return res
            },
            async logout() {
                await apiLogout()
                const tabbarStore = useTabbarStore()
                const routeStore = useRouteStore()
                const menuStore = useMenuStore()
                storage.local.remove('account')
                storage.local.remove('token')
                storage.local.remove('expiredAt')
                useCookies(['token']).remove('token')
                this.id = ''
                this.account = ''
                this.token = ''
                this.expiredAt = ''
                this.permissions = []
                tabbarStore.clean()
                routeStore.removeRoutes()
                menuStore.setActived(0)
            },
            // 获取我的权限
            async getPermissions() {
                this.permissions = await getUserPermission()
                return this.permissions
            },
            async setUserName(name) {
                this.account = name
                storage.local.set('account', this.account)
            },
            async setUserId(id) {
                this.id = id
                storage.local.set('id', this.id)
            },
            isHasBiddingPermissions() {
                return getInclude(this.permissions, ['bidding_work_todo', 'bidding_work_track', 'bidding_bidding_list']).length >= 1
            }
        }
    }
)

export default useUserStore
