import certificate from './certifi'
import manage from './manage'
import matter from './matter'
import approve from '@/router/modules/certification/approve'
import standard from './standard'
import literature from '@/router/modules/certification/literature'

export default {
    meta: {
        i18n: 'route.certification.name',
        icon: 'ri:database-line',
        auth: ['certification']
    },
    children: [
        certificate,
        manage,
        matter,
        approve,
        standard,
        literature
    ]
}
