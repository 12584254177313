const Layout = () => import('@/layout/index.vue')

const certifiChildren = [
    {
        path: '',
        name: 'literatureByq',
        component: () => import('@/views/certification/literature/material/index.vue'),
        meta: {
            title: '搜学林',
            // customIds: [1],
            auth: ['certification_literature'],
            cache: true
        }
    },
    {
        path: 'old',
        name: 'literatureOld',
        component: () => import('@/views/certification/literature/material/indexOld.vue'),
        meta: {
            title: '学林库',
            // customIds: [1],
            auth: ['certification_literature'],
            cache: true
        }
    },
    // {
    //     path: 'gdyjyjs',
    //     name: 'literatureGdyjyjs',
    //     component: () => import('@/views/certification/literature/material/index.vue'),
    //     meta: {
    //         title: '高电压绝缘技术',
    //         customIds: [17],
    //         auth: ['certification_literature'],
    //         cache: true
    //     }
    // },
    // {
    //     path: 'dgjsxb',
    //     name: 'literatureDgjsxb',
    //     component: () => import('@/views/certification/literature/material/index.vue'),
    //     meta: {
    //         title: '电工技术学报',
    //         customIds: [18],
    //         auth: ['certification_literature'],
    //         cache: true
    //     }
    // },
    // {
    //     path: 'dqyy',
    //     name: 'literatureDqyy',
    //     component: () => import('@/views/certification/literature/material/index.vue'),
    //     meta: {
    //         title: '电气应用',
    //         customIds: [20],
    //         auth: ['certification_literature'],
    //         cache: true
    //     }
    // },
    // {
    //     path: 'dgdq',
    //     name: 'literatureDgdq',
    //     component: () => import('@/views/certification/literature/material/index.vue'),
    //     meta: {
    //         title: '电工电气',
    //         customIds: [21],
    //         auth: ['certification_literature'],
    //         cache: true
    //     }
    // },
    {
        path: 'view/:certificationId',
        name: 'literatureView',
        component: () => import('@/views/certification/literature/material/view.vue'),
        meta: {
            title: '查看学林',
            sidebar: false,
            activeMenu: '/certification/literature',
            auth: ['certication_literature_view'],
            cache: true
        }
    },
    {
        path: 'add',
        name: 'literatureAdd',
        component: () => import('@/views/certification/literature/material/form.vue'),
        meta: {
            title: '新增学林',
            sidebar: false,
            activeMenu: '/certification/literature',
            auth: ['certication_literature_add']
        }
    },
    {
        path: ':id',
        name: 'literatureUpdate',
        component: () => import('@/views/certification/literature/material/form.vue'),
        meta: {
            title: '修改学林',
            sidebar: false,
            activeMenu: '/certification/literature',
            auth: ['certication_literature_update']
        }
    },
    {
        path: 'manage/category',
        name: 'literatureCategory',
        component: () => import('@/views/certification/literature/material/category/list.vue'),
        meta: {
            title: '类目管理',
            auth: ['certication_literature_cate'],
            cache: true
        }
    },
    {
        path: 'manage/category/add',
        name: 'literatureManageCategoryAdd',
        component: () => import('@/views/certification/literature/material/category/form.vue'),
        meta: {
            sidebar: false,
            activeMenu: '/certification/literature/manage/category',
            title: '新增类目',
            auth: ['certication_literature_cate']
        }
    },
    {
        path: 'manage/category/:id',
        name: 'literatureManageCategoryEdit',
        component: () => import('@/views/certification/literature/material/category/form.vue'),
        meta: {
            sidebar: false,
            activeMenu: '/certification/literature/manage/category',
            title: '修改类目',
            auth: ['certication_literature_cate']
        }
    },
    {
        path: 'manage/category/view/:categoryId',
        name: 'literatureManageCategoryView',
        component: () => import('@/views/certification/literature/material/category/view.vue'),
        meta: {
            sidebar: false,
            activeMenu: '/certification/literature/manage/category',
            title: '查看类目',
            auth: ['certication_literature_cate']
        }
    }
]

export default {
    path: '/certification/literature',
    redirect: { name: 'literatureNational' },
    component: Layout,
    meta: {
        title: '学林',
        icon: 'ri:database-line',
        auth: ['certification_literature']
    },
    children: certifiChildren
}
