import api, { getRequest, postRequest, putRequest } from '@/api'
import qs from 'qs'

/**
 * 获取项目列表
 */
export function getBiddingList(params) {
    return getRequest({
        url: '/api/bidding/',
        params: params,
        timeout: 60000,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
    })
}

/**
 * 获取项目列表
 */
export function getUnsignupPage(params) {
    return getRequest({
        url: '/api/bidding/unsignupPage',
        params: params,
        timeout: 60000,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
    })
}

/**
 * 获取项目列表
 */
export function unsignupExport(params) {
    return getRequest({
        url: '/api/bidding/unsignupPage/export',
        params: params,
        timeout: 60000,
        responseType: 'blob',
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
    })
}

export function getBidding(id) {
    return getRequest({
        url: `/api/bidding/${id}`
    })
}

export function updateBidding(data) {
    return putRequest({
        url: '/api/bidding/',
        data
    })
}

export function addBidding(data) {
    return postRequest({
        url: '/api/bidding/',
        data
    })
}

export function enterpriseLibraryList(params) {
    return getRequest({
        url: '/api/bidding/enterpriseLibrary',
        params: params,
        paramsSerializer: params => qs.stringify(params)
    })
}

export function customerList(params) {
    return getRequest({
        url: '/api/bidding/customer',
        params: params,
        paramsSerializer: params => qs.stringify(params)
    })
}

/**
 * 更新project数据
 * @param data
 */
export function biddingApprove(data) {
    return postRequest({
        url: '/api/bidding/approve',
        data,
        timeout: 60000

    })
}

export function getBiddingTodoList(params) {
    return getRequest({
        url: '/api/bidding/biddingTodoList',
        params: params,
        paramsSerializer: params => qs.stringify(params),
        timeout: 60000
    })
}

export function getBiddingCompleteList(params) {
    return getRequest({
        url: '/api/bidding/biddingCompleteList',
        params: params,
        paramsSerializer: params => qs.stringify(params),
        timeout: 60000
    })
}

/**
 * 招标统计
 */
export function getTenderMonthly(params) {
    return getRequest({
        url: '/api/bidding/statistics/monthly',
        paramsSerializer: params => qs.stringify(params),
        params: params,
        timeout: 60000
    })
}

/**
 * 招标统计导出
 */
export function getTenderMonthlyExport(params) {
    return getRequest({
        url: '/api/bidding/statistics/monthly/export',
        params: params,
        paramsSerializer: params => qs.stringify(params),
        responseType: 'blob',
        timeout: 60000
    })
}

/**
 * 招标统计
 */
export function getTenderAdvice(params) {
    return getRequest({
        url: '/api/bidding/statistics/advice',
        params: params,
        paramsSerializer: params => qs.stringify(params)

    })
}

/**
 * 招标统计
 */
export function biddingDailyExport(data) {
    return postRequest({
        url: '/api/bidding/statistics/daily/export/',
        data: data,
        responseType: 'blob',
        paramsSerializer: params => qs.stringify(params),
        timeout: 60000

    })
}

export function getBiddingSimilar(params) {
    return getRequest({
        url: '/api/bidding/search/bidding/similar',
        params: params,
        paramsSerializer: params => qs.stringify(params)
    })
}

export function getPreReportSimilar(params) {
    return getRequest({
        url: '/api/bidding/search/preReport/similar',
        params: params,
        paramsSerializer: params => qs.stringify(params),
        timeout: 60000
    })
}

export function getChannelList(params) {
    return getRequest({
        url: '/api/bidding/channel',
        params,
        paramsSerializer: params => qs.stringify(params, {
            allowDots: true
        })
    })
}

export function getStatisticsBadge() {
    return api.get('/api/bidding/statistics/badge', {
        showLoading: false,
        donotShowError: true
    })
}
export function getBiddingTagGroups() {
    return api.get('/api/bidding/tag/group/groupList', {
        showLoading: false,
        donotShowError: true
    })
}

export function getBiddingTeamList(params) {
    return getRequest({
        url: '/api/bidding/team',
        params: params,
        paramsSerializer: params => qs.stringify(params)
    })
}

export function revokeBiddingWork(biddingId, data) {
    return postRequest({
        url: `/api/bidding/${biddingId}/revoke`,
        data
    })
}

/**
 * 招标统计
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getStatisticsTender(date) {
    return api.get('api/bidding/statistics/tender', {
        params: {
            date
        }
    })
}

export function getManualFirstReview(params) {
    return getRequest({
        url: '/api/bidding/statistics/manual/first/review',
        params: params,
        timeout: 60000,
        paramsSerializer: params => qs.stringify(params)
    })
}
export function getManualFirstReviewExamine(params) {
    return getRequest({
        url: '/api/bidding/statistics/manual/first/review/examine',
        params: params,
        timeout: 60000,
        paramsSerializer: params => qs.stringify(params)
    })
}

export function getManualSecondReview(params) {
    return getRequest({
        url: '/api/bidding/statistics/manual/second/review',
        params: params,
        timeout: 60000,
        paramsSerializer: params => qs.stringify(params)
    })
}

export function getManualGetCheck(params) {
    return getRequest({
        url: '/api/bidding/statistics/manual/getCheck',
        params: params,
        timeout: 60000,
        paramsSerializer: params => qs.stringify(params)
    })
}

export function getManualTender(params) {
    return getRequest({
        url: '/api/bidding/statistics/manual/tender',
        params: params,
        timeout: 60000,
        paramsSerializer: params => qs.stringify(params)
    })
}

export function getManualTender2(params) {
    return getRequest({
        url: '/api/bidding/statistics/manual/tender/statistic',
        params: params,
        timeout: 60000,
        paramsSerializer: params => qs.stringify(params)
    })
}


export function getReUnsignup(params) {
    return getRequest({
        url: '/api/bidding/statistics/re/unsignup',
        params: params,
        timeout: 60000,
        paramsSerializer: params => qs.stringify(params)
    })
}

export function getOpenTender(params) {
    return getRequest({
        url: '/api/bidding/statistics/open-tender',
        params: params,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
    })
}

export function getTenderOpposite(params) {
    return getRequest({
        url: '/api/bidding/analyse/opposite/company',
        params: params,
        timeout: 60000,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
    })
}

export function getTenderOppositeExport(params) {
    return getRequest({
        url: '/api/bidding/analyse/opposite/company/export',
        params: params,
        timeout: 60000,
        paramsSerializer: params => qs.stringify(params, { allowDots: true }),
        responseType: 'blob'
    })
}

/**
 * 获取招标待办列表
 */
export function getBiddingTodoWorkList(params) {
    return getRequest({
        url: '/api/bidding/todo/list',
        params,
        timeout: 30000,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
    })
}

/**
 * 获取招标待办列表分类
 */
export function getBiddingTodoWorkListCategories(params) {
    return getRequest({
        url: '/api/bidding/todo/list/categories',
        params,
        timeout: 30000,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
    })
}

export function getProvinceAndCityTree() {
    return api.get('/api/bidding/location/province/city/tree')
}

/**
 * 获取已报名/未报名的招标信息excel
 */
export function signedExport(params) {
    return getRequest({
        url: '/api/bidding/export',
        params: params,
        timeout: 120000,
        responseType: 'blob',
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
    })
}

// 获取指定bidding的meta信息
export function getBiddingMeta(biddingId) {
    return getRequest({
        url: `/api/bidding/meta/${biddingId}/`
    })
}

export function setBiddingMeta(biddingId, data) {
    return postRequest({
        url: `/api/bidding/meta/${biddingId}`,
        data
    })
}

export function contractSourceId(sourceId, sourceType) {
    return getRequest({
        url: `/api/bidding/tenderInfo/contract/${sourceId}`,
        params: { sourceType }
    })
}
