const Layout = () => import('@/layout/index.vue')

const certifiChildren = [
    {
        path: '',
        name: 'certificationNational',
        component: () => import('@/views/certification/standard/material/index.vue'),
        meta: {
            title: '搜标准',
            // customIds: [1],
            auth: ['certication_standard'],
            cache: true
        }
    },
    {
        path: 'old',
        name: 'certificationNationalOld',
        component: () => import('@/views/certification/standard/material/indexOld.vue'),
        meta: {
            title: '标准库',
            // customIds: [1],
            auth: ['certication_standard'],
            cache: true
        }
    },
    // {
    //     path: 'industry',
    //     name: 'certificationIndustry',
    //     component: () => import('@/views/certification/standard/material/index.vue'),
    //     meta: {
    //         i18n: 'route.certification.industry',
    //         customIds: [2],
    //         auth: ['certication_standard'],
    //         cache: true
    //     }
    // },
    // {
    //     path: 'international',
    //     name: 'certificationInternational',
    //     component: () => import('@/views/certification/standard/material/index.vue'),
    //     meta: {
    //         i18n: 'route.certification.international',
    //         customIds: [3],
    //         auth: ['certication_standard'],
    //         cache: true
    //     }
    // },
    // {
    //     path: 'group',
    //     name: 'certificationGroup',
    //     component: () => import('@/views/certification/standard/material/index.vue'),
    //     meta: {
    //         i18n: 'route.certification.group',
    //         customIds: [4],
    //         auth: ['certication_standard'],
    //         cache: true
    //     }
    // },

    {
        path: 'view/:certificationId',
        name: 'standardView',
        component: () => import('@/views/certification/standard/material/view.vue'),
        meta: {
            i18n: 'route.certification.standard.view',
            sidebar: false,
            activeMenu: '/certification/standard',
            auth: ['certication_standard_view'],
            cache: true
        }
    },
    {
        path: 'add',
        name: 'standardAdd',
        component: () => import('@/views/certification/standard/material/form.vue'),
        meta: {
            i18n: 'route.certification.standard.add',
            sidebar: false,
            activeMenu: '/certification/standard',
            auth: ['certication_standard_add']
        }
    },
    {
        path: ':id',
        name: 'standardUpdate',
        component: () => import('@/views/certification/standard/material/form.vue'),
        meta: {
            i18n: 'route.certification.standard.update',
            sidebar: false,
            activeMenu: '/certification/standard',
            auth: ['certication_standard_update']
        }
    },
    {
        path: 'manage/category',
        name: 'standardManageCategory',
        component: () => import('@/views/certification/standard/material/category/list.vue'),
        meta: {
            i18n: 'route.certification.standard.material.config',
            auth: ['certication_standard_cate'],
            cache: true
        }
    },
    {
        path: 'manage/category/add',
        name: 'standardManageCategoryAdd',
        component: () => import('@/views/certification/standard/material/category/form.vue'),
        meta: {
            sidebar: false,
            activeMenu: '/certification/standard/manage/category',
            i18n: 'route.certification.standard.material.add',
            auth: ['certication_standard_cate']
        }
    },
    {
        path: 'manage/category/:id',
        name: 'standardManageCategoryEdit',
        component: () => import('@/views/certification/standard/material/category/form.vue'),
        meta: {
            sidebar: false,
            activeMenu: '/certification/standard/manage/category',
            i18n: 'route.certification.standard.material.update',
            auth: ['certication_standard_cate']
        }
    },
    {
        path: 'manage/category/view/:categoryId',
        name: 'standardManageCategoryView',
        component: () => import('@/views/certification/standard/material/category/view.vue'),
        meta: {
            sidebar: false,
            activeMenu: '/certification/standard/manage/category',
            i18n: 'route.certification.standard.material.view',
            auth: ['certication_standard_cate']
        }
    }
]

export default {
    path: '/certification/standard',
    redirect: { name: 'certificationNational' },
    component: Layout,
    meta: {
        i18n: 'route.certification.standard.name',
        icon: 'ri:database-line'
        // auth: ['certification_data']
    },
    children: certifiChildren
}
